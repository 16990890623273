/*Buttons*/
.btn{
  border: none !important;
  border-radius: 2px;
  color: $white;
  font-weight: 500;
  text-transform: uppercase;
  min-width: 100px;
  height: 30px;
  &:hover, &:focus{
    color: $white;
  }
  &:focus, &:focus:active{
    outline: none;
  }
  &.raised{
    @include shadow(1);
  }
  &.icon{
    width: 30px !important;
    min-width: auto;
    padding: 6px 8px;
    i{
      margin-left: 0;
      font-size: 18px;
      padding-left: 0px;
    }
  }
  &-link{
    color: $white !important;
    min-width: 100px !important;
    &:hover{
      text-decoration: none !important;
    }
  }
  &-no-bg{
    background: transparent !important;
  }
  i{
    font-size: 18px;
    vertical-align: top;
    margin-left: 3px;
  }
  &.btn-lg i{
    font-size: 24px;
  }
  &.btn-sm i{
    font-size: 13px;
  }
  &.btn-xs i{
    font-size: 10px;
    vertical-align: middle;
  }
}

.btn-group-xs>.btn, .btn-xs{
  font-size: 10px;
  height: 20px;
  line-height: 20px;
  min-width: 60px;
}

a, a.btn, a.btn:hover, a.btn:focus, a.btn:active:focus{
  color: $info-color;
  box-shadow: none;
}

a.btn:hover{
  text-decoration: underline;
}

//Button groups
.btn-group{
  box-shadow: none;
  .btn{
    border-radius: 2px;
    border: 1px solid;
  }
  &.btn-group-xs .btn{
    height: auto;
    min-width: auto;
  }
  &.raised{
    @include shadow(1);
  }
  .btn-default, .btn-default:active:focus {
  border-color: $default-color;
  }
  .btn-primary, .btn-primary:active:focus {
  border-color: $primary-color;
  }
  .btn-success, .btn-success:active:focus {
  border-color: $success-color;
  }
  .btn-info, .btn-info:active:focus {
  border-color: $info-color;
  }
  .btn-warning, .btn-warning:active:focus {
  border-color: $warning-color;
  }
  .btn-danger, .btn-danger:active:focus {
  border-color: $danger-color;
  }
}

.btn-group-vertical .btn-group .btn{
  border: 0;
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
  border-top-right-radius: 2px;
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
  border-bottom-left-radius: 2px;
}

.btn-toolbar .btn{
  min-width: auto;
}

.btn-group-justified .btn-group:not(:first-child):not(:last-child) .btn{
  border-left: 0;
  border-right: 0;
}

.btn-group-lg .btn i{
  font-size: 24px;
}

.btn-group-sm .btn i{
  font-size: 13px;
}

.btn-group-xs .btn i{
  font-size: 10px;
  vertical-align: middle;
}

//Sizes
.btn-lg, .btn-group-lg .btn{
  height: 48px;
  min-width: 126px;
  &.icon{
    width: 48px !important;
    min-width: inherit;
    i{
      margin-left: 0;
      font-size: 24px;
    }
  }
}

.btn-sm, .btn-group-sm .btn{
  height: 24px;
  min-width: 80px;
  line-height: 1;
  &.icon{
    width: 24px !important;
    min-width: inherit;
    padding-left: 7px;
    i{
      margin-left: 0;
      font-size: 12px;
    }
  }
}

a.btn{
  padding-left: 8px;
  padding-right: 8px;
  min-width: auto;
}

a.btn.btn-lg{
  line-height: 1.7;
}

a.btn.btn-sm{
  line-height: 1.2;
}

//Colors
.btn-default, .btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default:active:focus, .open>.dropdown-toggle.btn-default:focus , .open>.dropdown-toggle.btn-default, .open>.dropdown-toggle.btn-default:hover{
  background: $default-color;
  color: $white;
}

.btn-primary, .btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary:active:focus, .open>.dropdown-toggle.btn-primary:focus, .open>.dropdown-toggle.btn-primary, .open>.dropdown-toggle.btn-primary:hover{
  background: $primary-color;
}

.btn-success, .btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success:active:focus, .open>.dropdown-toggle.btn-success:focus, .open>.dropdown-toggle.btn-success, .open>.dropdown-toggle.btn-success:hover{
  background: $success-color;
}

.btn-info, .btn-info.disabled, .btn-info.disabled:hover, .btn-dinfo.disabled:focus, .btn-info.disabled:active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info:active:focus, .open>.dropdown-toggle.btn-info:focus, .open>.dropdown-toggle.btn-info, .open>.dropdown-toggle.btn-info:hover{
  background: $info-color;
}

.btn-warning, .btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning:hover, .btn-warning:focus,.btn-warning:active, .btn-warning:active:focus, .open>.dropdown-toggle.btn-warning:focus, .open>.dropdown-toggle.btn-warning, .open>.dropdown-toggle.btn-warning:hover{
  background: $warning-color;
}

.btn-danger, .btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger:active:focus, .open>.dropdown-toggle.btn-danger:focus, .open>.dropdown-toggle.btn-danger, .open>.dropdown-toggle.btn-danger:hover{
  background: $danger-color;
}

.btn-group.no-bg .dropdown-menu > li > a:hover, .btn-group.no-bg .dropdown-menu > li > a:focus{
  background: #f5f5f5;
}

.btn-no-bg.success-color.waves-effect .waves-ripple {
  background-color: rgba(153, 204, 0, 0.65);
}

.btn-no-bg.primary-color.waves-effect .waves-ripple {
  background-color: rgba(170, 102, 204, 0.65);
}

.btn-no-bg.info-color.waves-effect .waves-ripple {
  background-color: rgba(73, 206, 255, 0.65);
}

.btn-no-bg.warning-color.waves-effect .waves-ripple {
  background-color: rgba(255, 187, 51, 0.65);
}

.btn-no-bg.danger-color.waves-effect .waves-ripple {
  background-color: rgba(255, 95, 95, 0.65);
}



/*Labels and Badges*/
.label{
  text-transform: uppercase;
  font-size: 14px;
  border-radius: 2px;
  padding: .2em .8em;
  display: inline-block;
}

.label-default, .badge-default{
  background-color: $default-color;
}

.label-primary, .badge-primary{
  background-color: $primary-color;
}

.label-success, .badge-success{
  background-color: $success-color;
}

.label-info, .badge-info{
  background-color: $info-color;
}

.label-warning, .badge-warning{
  background-color: $warning-color;
}

.label-danger, .badge-danger{
  background-color: $danger-color;
}

.label-xs, .badge-xs{
  font-size: 10px;
}

.label-sm, .badge-sm{
  font-size: 12px;
}

.label-lg, .badge-lg{
  font-size: 18px;
}

.badge{
  border-radius: 18px;
  font-weight: 400;
}


/*Blockquote*/
blockquote{
  border: 0;
  footer {
    color: inherit;
    border-bottom: 4px solid #ccc;
    h4{
      font-weight: 400;
    }
    &:before{
      content: none;
    }
  }
  p{
    font-size: 14px;
    margin-top: 20px;
  }
  &:before, &:after{
    content: '';
    background: url("../img/quote-left.png");
    background-size: cover;
    display: inline-block;
    width: 78px;
    height: 73px;
    float: left;
    @media(max-width: 768px){
      width: 40px;
      height: 37px;
    }
    @media(max-width: 480px){
      content: none !important;
    }
  }
  &:after{
    background: url("../img/quote-right.png");
    background-size: cover;
    float: right;
    margin-right: 10px;
    margin-top: -40px;
    @media(max-width: 768px){
      margin-top: -20px;
    }
  }
  .b-text{
    padding-left: 105px;
    padding-right: 105px;
    @media(max-width: 768px){
      padding-left: 50px;
      padding-right: 60px;
    }
    @media(max-width: 480px){
      padding: 0 !important;
    }
  }
  &.blockquote-alt{
    footer{
      border: 0;
      color: $white;
      h4{
        line-height: 50px;
        padding: 0 20px;
      }
    }
  }
  &.bottom-footer footer{
    border-bottom: 0;
    border-top: 4px solid #ccc;
  }

  &.blockquote-primary footer{
    border-color: $primary-color;
  }
  &.blockquote-success footer{
    border-color: $success-color;
  }
  &.blockquote-info footer{
    border-color: $info-color;
  }
  &.blockquote-warning footer{
    border-color: $warning-color;
  }
  &.blockquote-danger footer{
    border-color: $danger-color;
  }
}

/*Lists*/

ul.list li{
  list-style-type: none;
  &:before{
    content: "\f26b";
    font-family: "Material-Design-Iconic-Font";
    font-size: 16px;
    margin-right: 10px;
    color: $primary-color;
  }
}

ol.list li{
  list-style-type: none;
  counter-increment: count-me;
  &:before{
    content: counter(count-me) ". ";
    margin-right: 5px;
    color: $primary-color;
  }
}
/*Progress bar*/
.progress{
  height: 10px;
  box-shadow: none;
  background: #f1f5f7;
  border-radius: $border-radius;
  box-shadow: 0px 1px 0px 0px rgba(211, 216, 218, 1) inset;
  .progress-bar-info{
    background-color: $info-color;
  }
  .progress-bar-danger{
    background-color: $danger-color;
  }
  .progress-bar-warning{
    background-color: $warning-color;
  }
  .progress-bar-success{
    background-color: $success-color;
  }
  .progress-bar-primary{
    background-color: $primary-color;
  }
  &.progress-bar-sm{
    height: 6px;
  }
  &.progress-bar-lg{
    height: 18px;
  }
}

.progress-bar{
  background-color: $default-color;
}

/*Tabs*/
.nav-tabs{
  border-bottom: 2px solid $warning-color;
  margin-bottom: 20px;
  >li{
    float: none;
    display: inline-block;
    vertical-align: top;
    >a{
      text-transform: uppercase;
      margin-right: 10px;
      font-size: 16px;
      font-weight: 500;
      color: $text-color;
      border-color: #eee;
      border-bottom-color: $warning-color;
      &:hover{
        border-bottom-color: $warning-color;
        background: $white;
      }
      @media(max-width: 768px){
        padding: 10px;
        font-size: 14px;
        margin: 0 !important;
      }
      i{
        @media(min-width: 768px){
          margin-right: 5px;
        }
      }
      span{
        @media(max-width: 767px){
          display: none;
        }
      }
    }
  }
  >li.active>a, >li.active>a:focus, >li.active>a:hover{
    border: 1px solid $warning-color;
    background: $warning-color;
    color: $white;
  }
}

.vertical-tabs {
  @media(min-width: 768px){
    .nav-tabs{
      border-bottom: none;
      border-right: 2px solid $warning-color;
      >li{
        display: block;
        margin: 0;
        >a{
          margin: 0 !important;
          border-radius: 0;
          border-bottom-color: #eee;
        }
        &:first-child{
          >a{
            border-top-left-radius: $border-radius;
            border-bottom: 0;
          }
        }
        &:last-child{
          >a{
            border-bottom-left-radius: $border-radius;
            border-top: 0;
          }
        }
      }
      &.nav-tabs-right{
        border-left: 2px solid $warning-color;
        border-right: 0;
        > li{
          &:first-child{
            >a{
              border-top-left-radius: 0;
              border-top-right-radius: $border-radius;
            }
          }
          &:last-child{
            >a{
              border-bottom-left-radius: 0;
              border-bottom-right-radius: $border-radius;
            }
          }
        }
      }
    }
  }
}

/*Pills*/
.nav-pills>li>a{
  border-radius: $border-radius;
}

.nav-pills>li.active>a, .nav-pills>li.active>a:focus, .nav-pills>li.active>a:hover{
  background: $success-color;
}

/*Pagination*/
.pagination li{
  &:first-of-type, &:last-of-type{
    display: inline;
    a{
      border-radius: 0;
    }
  }
  a{
    border-radius: 0;
  }
}
.pagination li.active a{
  background: $success-color;
  border-color: $success-color !important;
  &:hover, &:focus{
    background: $success-color;
    color: $white;
  }
}

.pagination li a, .pager li a{
  color: $success-color;
  transition: none !important;
  box-shadow: none !important;
  z-index: 1 !important;
  line-height: 1.3333333 !important;
  border: 1px solid #ddd !important;
  &:hover, &:focus{
    color: $white;
    background: $success-color;
    border-color: $success-color !important;
  }
}

.pagination>.disabled>a, .pagination>.disabled>a:focus, .pagination>.disabled>a:hover, .pagination>.disabled>span, .pagination>.disabled>span:focus, .pagination>.disabled>span:hover{
  border-color: #ddd !important;
}

.pagination-lg li a{
  font-size: 18px !important;
}

.pagination-sm li a{
  font-size: 12px !important;
}

.nav-tabs.text-right>li>a{
  margin-left: 10px;
  margin-right: 0;
}

/*Popover*/
.popover{
  font-family: $font;
  border-radius: $border-radius;
  border-color: $white;
  @include shadow(1);
  &.top>.arrow{
    border-top-color: #ddd;
  }
  &.bottom>.arrow{
    border-bottom-color: #ddd;
  }
  &.left>.arrow{
    border-left-color: #ddd;
  }
  &.right>.arrow{
    border-right-color: #ddd;
  }
}


/*Panels*/
.panel{
  border: 0;
  border-radius: 0;
  box-shadow: none;
  .panel-body{
    border: 0 !important;
  }
}

.panel-heading{
  border: 0;
  border-radius: 0;
  background-image: linear-gradient($warning-color,$warning-color),linear-gradient(#d2d2d2,#d2d2d2);
  background-size: 0 2px,100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom,center calc(100% - 1px);
  background-color: transparent;
  a:focus{
    text-decoration: none;
  }
  &.active{
    animation: "input-highlight" .3s forwards;
  }
}

@keyframes 'input-highlight'{
  0% {
    background-size: 0 2px, 100% 1px;
  }
  100% {
    background-size: 100% 2px, 100% 1px;
  }
}

/*Modal*/
.modal .modal-content{
  border: 0;
  border-radius: $border-radius;
  @include shadow(2);
  >div{
    padding: 15px 25px;
  }
  .modal-header{
    border: 0;
  }
}

/*Dropdown*/
.dropdown, .dropup{
  display: inline-block;
}

.dropdown-toggle{
  border: 0 !important;
}

.btn-group>.btn+.dropdown-toggle{
  min-width: inherit;
}

.dropdown .dropdown-menu, .dropup .dropdown-menu, .btn-group .dropdown-menu{
  opacity: 0;
  padding: 0;
  border: 0;
  transform: scale(0);
  transition: all .2s;
  backface-visibility: hidden;
  @include shadow(1);
  display: block;
  border-color: transparent;
  border-radius: 2px;
  >li>a{
    padding: 8px 20px;
    color: $white;
    &:hover,&:focus{
      background: rgba(0,0,0,0.2);
    }
    .zmdi{
      vertical-align: text-bottom;
      font-size: 18px;
      width: 30px;
    }
  }
  &.pull-right{
    right: 0;
    left: auto;
  }
  .divider{
    margin: 0;
    background: rgba(0,0,0,0.2);
  }
}

.dropdown, .dropup, .btn-group{
  &.default .dropdown-menu{
    background: $default-color;
  }
  &.primary .dropdown-menu{
    background: $primary-color;
  }
  &.success .dropdown-menu{
    background: $success-color;
  }
  &.info .dropdown-menu{
    background: $info-color;
  }
  &.warning .dropdown-menu{
    background: $warning-color;
  }
  &.danger .dropdown-menu{
    background: $danger-color;
  }
}

.dropdown .dropdown-menu , .btn-group .dropdown-menu{
  transform-origin: top left;
  &.pull-right{
    transform-origin: top right;
  }
}

.dropup .dropdown-menu{
  transform-origin: bottom left;
  &.pull-right{
    transform-origin: bottom right;
  }
}

.dropdown[data-animation] .dropdown-menu, .dropup[data-animation] .dropdown-menu, .btn-group[data-animation] .dropdown-menu{
  transform: none;
  display: none;
}

.dropdown.open[data-animation] .dropdown-menu, .dropup.open[data-animation] .dropdown-menu, .btn-group.open[data-animation] .dropdown-menu{
  transform: none;
  display: block;
}

.dropdown.open .dropdown-menu, .dropup.open .dropdown-menu, .btn-group.open .dropdown-menu{
  opacity: 1;
  transform: scale(1);
}


/*Breadcrumbs*/
.breadcrumb{
  border-radius: 0;
  background-color: transparent;
  margin-bottom: 10px;
  &.breadcrumb-arrow>li+li:before {
    content: "\00bb\00a0";
  }
  a:hover{
    color: $info-color;
  }
  a i{
    margin-right: 5px;
    font-size: 16px;
  }
}


/*Quantity buttons*/
.qty{
  display: inline-block;
}

input.qty-number{
  height: 24px;
  width: 24px;
  background: $white;
  border: 0;
  text-align: center;
  font-weight: 700;
}

input.qtyplus, input.qtyminus{
  height: 24px;
  width: 24px;
  background: $white;
  border: 1px solid $default-color;
  font-weight: 500;
  &:focus{
    outline: none;
  }
}

.qty-primary{
  .qtyplus, .qtyminus{
    color: $primary-color;
  }
}

.qty-success{
  .qtyplus, .qtyminus{
    color: $success-color;
  }
}

.qty-info{
  .qtyplus, .qtyminus{
    color: $info-color;
  }
}

.qty-warning{
  .qtyplus, .qtyminus{
    color: $warning-color;
  }
}

.qty-danger{
  .qtyplus, .qtyminus{
    color: $danger-color;
  }
}

.qty-alt{
  .qtyplus, .qtyminus{
    color: $white;
    border: 0;
    background: $default-color;
  }
  &.qty-primary{
    .qtyplus, .qtyminus{
      background: $primary-color;
    }
  }

  &.qty-success{
    .qtyplus, .qtyminus{
      background: $success-color;
    }
  }

  &.qty-info{
    .qtyplus, .qtyminus{
      background: $info-color;
    }
  }

  &.qty-warning{
    .qtyplus, .qtyminus{
      background: $warning-color;
    }
  }

  &.qty-danger{
    .qtyplus, .qtyminus{
      background: $danger-color;
    }
  }
}

/*Radio buttons*/
.radio.material{
  padding: 0;
  i{
    display: none;
  }
}


.radio {
  padding-left: 20px;
  display: inline-block;
  label{
    padding-left: 5px;
  }
    i {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 5px;
    cursor: pointer;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $default-color;
      border-radius: 50%;
      background-color: $white;
      transition: border 0.15s ease-in-out;
      cursor: pointer;
    }
    &:after {
      display: inline-block;
      position: absolute;
      content: " ";
      width: 16px;
      height: 16px;
      left: 4px;
      top: 4px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $default-color;
      transform: scale(0, 0);
      transition: transform 0.3s cubic-bezier(0.8, -0.33, 0.2, 1.33);
      cursor: pointer;
    }
  }
  &.radio-alt{
    input[type="radio"]:checked + i::after{
      background-color: $white;
    }
    i{
      &:before{
        border: 0;
        background-color: $default-color;
      }
      &:after{
        background-color: $white;
      }
    }
  }
}

.radio input[type="radio"] {
  opacity: 0;
  z-index: 1;
}
.radio input[type="radio"]:focus + i::before {
  outline: none;
}
.radio input[type="radio"]:checked + i::after {
  transform: scale(1, 1);
}
.radio input[type="radio"]:disabled + i {
  opacity: 0.65;
  &:before{
    cursor: not-allowed;
  }
}

.radio-primary input[type="radio"]:checked + i::after {
  background-color: $primary-color;
}

.radio-danger input[type="radio"]:checked + i::after {
  background-color: $danger-color;
}

.radio-info input[type="radio"]:checked + i::after {
  background-color: $info-color;
}

.radio-warning input[type="radio"]:checked + i::after {
  background-color: $warning-color;
}

.radio-success input[type="radio"]:checked + i::after {
  background-color: $success-color;
}

.radio-alt.radio-primary i::before {
  background-color: $primary-color;
}

.radio-alt.radio-success i::before {
  background-color: $success-color;
}

.radio-alt.radio-info i::before {
  background-color: $info-color;
}

.radio-alt.radio-warning i::before {
  background-color: $warning-color;
}

.radio-alt.radio-danger i::before {
  background-color: $danger-color;
}

.radio-text span{
  position: absolute;
  top: 4px;
  left: -19px;
  width: 32px;
  text-align: center;
  font-size: 12px;
  color: $white;
}

.radio.radio-text input[type="radio"]:checked + i::before{
  transform: scale(1.3, 1.3);
  background-color: $success-color;
  transition: .15s;
}

.radio.radio-text input[type="radio"]:checked + i::after{
  background-color: transparent;
  transform: scale(0, 0);
}


/*Checkboxes*/

.checkbox.material{
  padding: 0;
  i{
    display: none;
  }
}

.checkbox {
  padding-left: 20px;
  display: inline-block;
  label{
    padding-left: 0;
  }
    i {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 5px;
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 24px;
      height: 24px;
      left: 0;
      margin-left: -20px;
      border: 1px solid $default-color;
      border-radius: 3px;
      background-color: $white;
      transition: all 0.15s ease-in-out;
    }
    &:after {
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 0;
      top: 0;
      margin-left: -20px;
      margin-top: -1px;
      padding-left: 5px;
      padding-top: 0;
      font-size: 20px;
      color: #555555;
    }
  }
}

.checkbox input[type="checkbox"]{
  opacity: 0;
  z-index: 1;
}
//.checkbox input[type="checkbox"]:focus + i::before{
//  outline: thin dotted;
//  outline: 5px auto -webkit-focus-ring-color;
//  outline-offset: -2px;
//}
.checkbox input[type="checkbox"]:checked + i::after{
  font-family: "Material-Design-Iconic-Font";
  font-style: normal;
  content: "\f26b";
}
.checkbox input[type="checkbox"]:disabled + i{
  opacity: 0.65;
}
.checkbox input[type="checkbox"]:disabled + i::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox-primary input[type="checkbox"]:checked + i::after{
  color: $primary-color;
}

.checkbox-danger input[type="checkbox"]:checked + i::after{
  color: $danger-color;
}

.checkbox-info input[type="checkbox"]:checked + i::after {
  color: $info-color;
}

.checkbox-warning input[type="checkbox"]:checked + i::after {
  color: $warning-color;
}

.checkbox-success input[type="checkbox"]:checked + i::after {
  color: $success-color;
}


.checkbox-alt{
  input[type="checkbox"]:checked + i::before{
    background-color: $default-color;
    border-color: $default-color;
  }
  input[type="checkbox"]:checked + i::after{
    color: $white;
  }
  &.checkbox-primary input[type="checkbox"]:checked + i::before{
    background-color: $primary-color;
    border-color: $primary-color;
  }

  &.checkbox-danger input[type="checkbox"]:checked + i::before{
    background-color: $danger-color;
    border-color: $danger-color;
  }

  &.checkbox-info input[type="checkbox"]:checked + i::before {
    background-color: $info-color;
    border-color: $info-color;
  }

  &.checkbox-warning input[type="checkbox"]:checked + i::before {
    background-color: $warning-color;
    border-color: $warning-color;
  }

  &.checkbox-success input[type="checkbox"]:checked + i::before {
    background-color: $success-color;
    border-color: $success-color;
  }
}


/*Media*/
a.thumbnail.active, a.thumbnail:focus, a.thumbnail:hover {
  border-color: $light-blue;
}


/*Alerts*/
.alert{
  padding: 20px;
  border-radius: $border-radius;
  font-size: 16px;
  line-height: 1.6;
  color: #2a3e5f;
  >p{
    margin-bottom: 20px;
  }
  h5{
    margin-top: 0;
  }
  &-dismissible{
    padding-right: 35px;
  }
  &-warning{
    background: #ffe7b7;
    border-color: #ffe0a3;
  }
  &-danger{
    background: #ffe4e4;
    border-color: #ffcfcf;
  }
  &-info{
    background: #b8ecff;
    border-color: #a6e7ff;
  }
  &-success{
    background: #edffb5;
    border-color: #d7f283;
  }
}

.close{
  opacity: .3;
  font-size: 30px;
  line-height: 0.7;
}

/*Form Elements*/
.form-control.material{
  color: $text-color;
  padding-left: 0;
  border: 0;
  border-radius: 0;
  background-image: linear-gradient($primary-color,$primary-color),linear-gradient($default-color,$default-color);
  background-size: 0 3px,100% 1px;
  background-repeat: no-repeat; 
  background-position: center bottom,center calc(100% - 1px);
  background-color: transparent;
  box-shadow: none;
  min-height: 44px;
  &:focus{
    outline: none;
    animation: input-highlight .3s forwards;
    box-shadow: none;
    background-size: 100% 2px,100% 1px;
  }
  &[disabled]{
    opacity: 0.5;
    background-image: linear-gradient($primary-color,$primary-color),linear-gradient($default-color,$default-color);
    background-size: 0 3px,100% 1px;
    background-repeat: no-repeat; 
    background-position: center bottom,center calc(100% - 1px);
    background-color: transparent;
  }
  &.input-warning{
    background-image: linear-gradient($warning-color,$warning-color),linear-gradient($default-color,$default-color);
    &:focus{
      box-shadow: none;
    }
  }
  &.input-danger{
    background-image: linear-gradient($danger-color,$danger-color),linear-gradient($default-color,$default-color);
    &:focus{
      box-shadow: none;
    }
  }
  &.input-success{
    background-image: linear-gradient($success-color,$success-color),linear-gradient($default-color,$default-color);
    &:focus{
      box-shadow: none;
    }
  }
  &.input-primary{
    background-image: linear-gradient($primary-color,$primary-color),linear-gradient($default-color,$default-color);
    &:focus{
      box-shadow: none;
    }
  }
  &.input-info{
    background-image: linear-gradient($info-color,$info-color),linear-gradient($default-color,$default-color);
    &:focus{
      box-shadow: none;
    }
  }
}

@keyframes(input-highlight){
  0% {
    background-size: 0 3px, 100% 1px;
  }
  100% {
    background-size: 100% 3px, 100% 1px;
  }
}


.input-group.material{
  .input-group-addon {
	border: 0;
    background: none;
  }
}

.form-group.has-icon {
  .form-control {
    padding-right: 35px;
  }
  .form-icon{
    position: absolute;
    right: 10px;
    top: 8px;
  }
}
.form-control{
//  line-height: 43px;
  height: 43px;
  box-shadow: none;
  border-color: #c6c5c5;
  border-radius: $border-radius;
  font-size: 16px;
  &:focus{
    border-color: $default-color;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(198, 197, 197,.6);
  }
  &[disabled]{
    border-color: #e2e2e2;
    background: $white;
    &:-moz-placeholder {
      color: #e2e2e2;
    }
    &::-moz-placeholder {
      color: #e2e2e2;
    }
    &:-ms-input-placeholder {
      color: #e2e2e2;
    }
    &::-webkit-input-placeholder {
      color: #e2e2e2;
    }
  }
  &.input-lg{
    height: 63px;
    line-height: 63px;
    font-size: 24px;
  }
  &.input-sm{
    height: 33px;
    line-height: 33px;
    font-size: 12px;
  }
  &.input-warning{
    border-color: $warning-color;
    &:focus{
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(255,187,51,.6);
    }
  }
  &.input-danger{
    border-color: $danger-color;
    &:focus{
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(255,95,95,.6);
    }
  }
  &.input-success{
    border-color: $success-color;
    &:focus{
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(153,204,0,.6);
    }
  }
  &.input-primary{
    border-color: $primary-color;
    &:focus{
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(170,102,204,.6);
    }
  }
  &.input-info{
    border-color: $info-color;
    &:focus{
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(73,206,255,.6)
    }
  }
}

.input-group-addon {
  .btn{
    height: 41px;
    min-width: inherit;
    width: 100%;
    &:first-child{
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:first-child{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

//.input-group-addon {
//    padding: 6px 12px;
//    font-size: 14px;
//    font-weight: 400;
//    line-height: 1;
//    color: #555;
//    text-align: center;
//    background-color: #eee;
//    border: 1px solid #48CDFE;
//    border-radius: 4px;
//}

.form-horizontal .control-label{
  padding-top: 11px;
}

.form-inline{
  .form-group{
    @media(min-width: 768px){
      margin-right: 10px;
    }
  }
}

label{
  font-weight: 500;
  font-size: 16px;
}

textarea{
  box-shadow: none;
  border-color: #c6c5c5;
  border-radius: $border-radius;
  font-size: 16px;
}

.bootstrap-select{
  .btn{
    height: 43px;
    background: $white;
    color: #999;
    border: 1px solid #c6c5c5 !important;
    font-weight: 400;
    text-transform: none !important;
    &:focus{
      outline: none !important;
    }
  }
  .dropdown-menu{
    padding: 0;
    li a{
      color: $text-color;
      position: relative;
      &:focus{
        outline: none;
      }
    }
    li.selected .check-mark{
      display: inline-block !important;
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 18px;
      margin: 0 !important;
    }
    li.selected.active a{
      color: $white;
      background: $default-color;
    }
  }
  .notify{
    background: rgba($danger-color, 0.9) !important;
    text-align: center;
    color: $white;
    border: 0 !important;
    bottom: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
    opacity: 1 !important;
  }
}

.form-wizard label.error{
  color: $danger-color;
  font-weight: normal;
  font-size: 12px;
}

//toggles
input.toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
input.toggle + i {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
  padding: 2px;
  width: 50px;
  height: 18px;
  background-color: #dddddd;
  border-radius: 60px;
}

input.toggle + i:before,
input.toggle + i:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 0px;
  bottom: 1px;
  content: "";
}
input.toggle + i:before {
  right: 1px;
  background-color: #b9b8b8;
  border-radius: 60px;
  transition: background 0.4s;
}
input.toggle + i:after {
  width: 25px;
  height: 25px;
  background-color: #f1f1f1;
  border-radius: 100%;
  margin-top: -5px;
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.31);
  transition: margin, .4s;
}

input.toggle:checked + i:after {
  margin-left: 25px !important;
}

input.toggle:disabled + i{
  cursor: not-allowed;
  opacity: 0.6;
}

input.toggle-primary:checked + i:before {
  background-color: lighten($primary-color, 10%);
}
input.toggle-primary:checked + i:after {
  background-color: $primary-color;
}
input.toggle-success:checked + i:before {
  background-color: lighten($success-color, 8%);
}
input.toggle-success:checked + i:after {
  background-color: $success-color;
}
input.toggle-danger:checked + i:before {
  background-color: lighten($danger-color, 10%);
}
input.toggle-danger:checked + i:after {
  background-color: $danger-color;
}
input.toggle-warning:checked + i:before {
  background-color: lighten($warning-color, 12%);
}
input.toggle-warning:checked + i:after {
  background-color: $warning-color;
}
input.toggle-info:checked + i:before {
  background-color: lighten($info-color, 15%);
}
input.toggle-info:checked + i:after {
  background-color: $info-color;
}

//list group
.list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover{
  border-color: $info-color;
  background-color: $info-color;
}

/*Top navigation*/

nav.navbar {
  position: relative;
  padding: 0;
  height: 70px;
  background: #49CEFF;
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
  box-shadow: 0 1.5px 4px rgba(0, 0, 0, 0.1), 0 1.5px 6px rgba(0, 0, 0, 0.1);
  z-index: 12;
  .navbar-header {
    width: 70px;
    padding-left: 25px;
    transition: width .3s .02s;
    float: left;
  }
  .navbar-container {
    margin-left: 84px;
    transition: margin-left .3s .02s;

  }
  a {
    line-height: 70px;
    color: $white;
    font-size: 22px;
    &.page-title{
      @media(max-width: 480px){
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 160px;
        display: inline-block;
      }
    }
  }
  .menu-toggle {
    margin-right: 35px;
    font-size: 24px;
    position: relative;
    float: left;
    transition: transform .2s;
    &.toggled {
      transform: rotate(360deg);
      margin-right: 31px;
      &:before{
        transform: scale(1);
      }
    }
    &:before{
      content: "";
      display: block;
      position: absolute;
      top: 14px;
      left: -11px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(255,255,255,0.2);
      transition: all 250ms;
      transform: scale(0);
    }
  }
  .logo{
    display: none;
  }
  .search-top {
    font-size: 30px;
  }

  .right-menu{
    margin-left: 10px;
    >li {
      display: inline-block;
      line-height: 70px;
      text-align: center;
      vertical-align: middle;
      &.full-toggle{
        display: none;
      }
      >a {
        font-size: 26px;
        color: $white;
        padding: 0;
        min-width: 60px;
        cursor: pointer;
        &:focus,
        &:hover {
          background-color: transparent;
        }
        &:before{
          content: "";
          display: block;
          position: absolute;
          top: 14px;
          left: 10px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: rgba(255,255,255,0.2);
          transition: all 250ms;
          transform: scale(0);
        }
      }
      &.open>a:before{
        transform: scale(1);
      }
      &.open a{
        background-color: transparent;
      }
      &.notification>a .badge{
        position: absolute;
        top: 20px;
        right: 10px;
      }
      &.notification .dropdown-menu{
        transform-origin: top right;
        transition: all .1s;
        border-radius: $border-radius;
        overflow: hidden;
        right: 0;
        top: 55px;
        left: auto;
        width: 350px;
        margin: 0;
        @include shadow(2);
        @media(max-width: 480px){
          width: 305px;
          left: -130px;
        }
        h4, a{
          color: #828282;
          background: $white;
          line-height: 50px;
          border-bottom: 1px solid $bg-color;
        }
        a{
          display: block;
          font-size: 14px;
          line-height: 40px;
          padding: 0 20px;
        }
        .clear-all {
          border: 0;
          background: $info-color;
          color: $white;
          text-align: center;
          font-size: 18px;
        }
        .notification-container {
          max-height: 110px;
        }
        .check-ok{
          position: absolute;
          text-align: center;
          top: 50%;
          left: 50%;
          color: $success-color;
          transform: translate(-50%, -50%);
          display: none;
          i{
            font-size: 90px;
            line-height: 110px;
            margin-top: 20px;
          }
        }
      }
      &.more-options.dropdown .dropdown-menu {   
        transform-origin: top right;
        transition: all .1s;
      }
      .more-opt-container {
        background: $white;
        border-radius: $border-radius;
        position: absolute;
        right: 0;
        left: auto;
        top: 53px;
        padding: 0;
        border: 0;
        width: 300px;
        @include shadow(1);
        @media(max-width: 480px){
          left: -64px;
        }
        a {
          display: inline-block;
          float: left;
          text-align: center;
          padding: 10px;
          width: 100px;
          color: #7F7F7F;
          font-size: 16px;
          font-weight: 400;
          line-height: 2;
          transition: background .2s;
          position: relative;
          &:hover{
            background: #eee;
          }
          i{
            display: block;
            font-size: 30px;
          }
          span{
            position: absolute;
            top: 5px;
            right: 25px;
          }
        }
      }
    }
  }
}


//Fixed menu
body.fixed-all, body.fixed-all-example {
  overflow-y: visible;
  nav.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  aside.sidebar, .side-panel {
    position: fixed;
    top: 70px;
  }
  &.horizontal-menu .side-panel{
    position: absolute !important;
    z-index: 9 !important;
    top: 70px !important;
  }
  .container-fluid {
    margin-top: 70px;
  }
}

//Menu closing/opening
body.open-menu aside.sidebar, body.fixed-sidebar-example aside.sidebar {
  transform: translate3d(0, 0, 0);
  width: 280px;
  opacity: 1;
}

//Fixed Sidebar
body.fixed-sidebar, body.fixed-sidebar-example{
  @media(min-width: 1200px){
    aside.sidebar{
      transform: translate3d(0, 0, 0);
      width: 280px;
      opacity: 1;
    }
    .menu-toggle {
      display: none;
    }
    nav.navbar .navbar-container{
      margin-left: 20px;
      .page-title{
        padding-left: 30px;
      }
    }
    .container-fluid{
      margin-left: 280px;
    }
    nav.navbar .navbar-header{
      width: 280px;
    }
    nav.navbar .logo{
      display: inline !important;
      padding-left: 50px;
    }
  }
}

/*Sidebar menu*/

aside.sidebar {
  width: 250px;
  transform: translate3d(-280px, 0, 0);
  transition: transform .3s, width .3s, opacity .2s;
  background: #19283F;
  position: absolute;
  opacity: 0;
  height: calc(100% - 70px);
  overflow: hidden;
  @include shadow(2);
  z-index: 11;
  li a {
    background: none;
    color: $white;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 60px;
    &:hover, &:focus {
      background: rgba(0,0,0,0.1);
    }
    &.active {
      color: #EAEAEA;
    }
    .label {
      margin-top: 20px;
      min-width: 22px;
      height: 22px;
      text-align: center;
      line-height: 22px;
      padding: 0 3px;
    }
    i{
      display: none;
    }
  }
  .nav-inside li a {
    padding-left: 40px;
    line-height: 50px;
    background: #121D2D;
  }
  li.active a{
    background: #121D2D;
  }
  li.active>a{
    color: $white;
    font-weight: 700;

  }
  li.active:before{
    content: "";
    width: 3px;
    height: 100%;
    position: absolute;
    background: $amber;
    z-index: 2;
    opacity: 1;
  }
}

.profile-sidebar {
  padding: 22px 30px 0 30px;
  img {
    max-width: 86px;
  }
  .profile-userpic {
    position: relative;
    margin-bottom: 5px;
    .online {
      position: absolute;
      bottom: -1px;
      right: 50%;
      margin-right: -25px;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: $light-green;
      border: 2px white solid;
    }
  }
  .profile-usertitle {
    color: $white;
    .name {
      font-size: 20px;
    }
    .city {
      i {
        margin-right: 8px;
      }
    }
  }
  .profile-activity {
    color: $white;
    text-transform: uppercase;
    margin-top: 15px;
    margin-left: -30px;
    margin-right: -30px;
    div {
      width: 50%;
      border-top: 1px solid #121D2D;
      border-bottom: 1px solid #121D2D;
      padding: 15px 0;
      &:first-child {
        border-right: 1px solid #121D2D;
      }
    }
    span {
      color: $amber;
      font-size: 50px;
      line-height: 57px;
    }
  }
}

.layout-settings {
  color: #597092;
  font-size: 14px;
  font-weight: 400;
  line-height: 45px;
  .checkbox{
    line-height: 1.5;
  }
}


/*Expanding Searchbar*/

$tl: 0.5s; //transition length
.searchbox {
  position: relative;
  min-width: 45px;
  margin-top: 13px;
  width: 0%;
  height: 45px;
  float: right;
  overflow: hidden;
  transition: width $tl, border-radius $tl, background $tl, box-shadow $tl;
  z-index: 2;
}

.searchbox-input {
  top: 0;
  right: 0;
  border: 0;
  outline: 0;
  background: $white;
  width: 100%;
  height: 45px;
  margin: 0;
  padding: 0px 55px 0px 20px;
}

.searchbox-icon,
.searchbox-submit {
  width: 45px;
  height: 45px;
  display: block;
  position: absolute;
  top: 0;
  font-size: 22px;
  right: 0;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  line-height: 45px;
  text-align: center;
  cursor: pointer;
  color: $white;
  background: #49CEFF;
  transition: background 0.8s;
}

.searchbox-open {
  width: 250px;
  @include shadow(1);
  border-radius: $border-radius;
  transition: width $tl cubic-bezier(.11, 1.21, .37, 1.21), border-radius $tl, background $tl;
  .searchbox-icon,
  .searchbox-submit {
    background: transparent !important;
    color: $black;
    transition: background .1s;
  }
}


.menu-overlay{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.3);
  display: none;
}


//light menu
.light-menu, .light-menu-example, .sidebar.sidebar-default{
  .profile-sidebar .profile-activity div{
    border-color: rgba(0,0,0,0.1);
  }
  .profile-sidebar .profile-usertitle .city{
    color: $white;
  }
  aside.sidebar{
    background: $info-color;
    li a{
      color: $white;
      background: none;
    }
    li.active a{
      background: rgba(0,0,0,0.1);
    }
  }
}


//Icon Menu
.icon-logo{
  display: none;
  background:  url(../img/icon_logo.png) no-repeat center center;
  height: 70px;
  width: 45px;
}
body.icon-menu{  
  &.boxed-layout .container-fluid{
    margin-left: auto;
  }
}

body.fixed-sidebar.icon-menu nav.navbar .logo, body.fixed-sidebar-example.icon-menu nav.navbar .logo{
  display: none !important;
}

body.fixed-sidebar.icon-menu nav.navbar .navbar-container .page-title, body.fixed-sidebar-example.icon-menu nav.navbar .navbar-container .page-title{
  padding-left: 0;
}

body.fixed-sidebar.icon-menu nav.navbar .navbar-header, body.fixed-sidebar-example.icon-menu nav.navbar .navbar-header{
  width: 100px;
}

body.icon-menu{
  @media(min-width: 992px){
    .navbar-header{
      padding-left: 0;
      height: 70px;
      width: 100px;
      text-align: center;
    }
    .icon-logo{
      display: inline-block;
    }
    .nav.nav-inside{
    position: fixed;
    width: 280px;
    left: 100px;
    top: 0;
    height: 100%;
    background: #121D2D;
    display: none;
    .nav-inside{
      left: 380px;
    }
    li.menu-child>a:after{
      content: "\f2fb";
      font-family: 'Material-Design-Iconic-Font';
      font-size: 18px;
      float: right;
      margin-top: -3px;
    }
    .inside-title{
      display: block;
      font-size: 30px;
      line-height: 84px;
      text-align: center;
      color: #597092;
      &:after{
        content: "";
        display: block;
        height: 1px;
        width: 240px;
        background: #597092;
        margin-left: 20px;
      }
    }
    li a{
      text-align: left;
      padding-left: 25px;
      width: 100%;
      display: block;
      &:hover{
        background: rgba(0,0,0,0.3);
      }
    }
  }
  .layout-settings {
  font-size: 13px;
    line-height: 45px;
  }
    nav.navbar .navbar-container{
      margin-left: 130px;
    }
    .profile-sidebar-container, .nav>li>a>span{
      display: none;
    }
    .menu-toggle {
      display: none;
    }
    .container-fluid{
      margin-left: 100px;
    }
    aside.sidebar{
    width: 100px;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    li .nav-inside li a i{
      display: none;
    }
    li a {
      padding: 15px;
      text-align: center;
      font-size: 13px;
      line-height: 1;
      &:hover, &:focus{
        background: #121D2D;
      }
      i{
        display: block;
        font-size: 32px;
        margin-bottom: 8px;
        color: #A3AFC1;
      }
    }
    li:hover a, li:focus a{
      background: #121D2D;
      z-index: 99;
    }
    li:hover > .nav.nav-inside{
      display: block;
    }
  }
}
}

body.icon-menu.light-menu{
  aside.sidebar li a{
    &:hover, &:focus{
      background: #3cb6e3;
    }
    i{
      color: $white;
    }
  }
  aside.sidebar li:hover a, aside.sidebar li:focus a, .nav.nav-inside{
    background: #3cb6e3;
  }
  .nav.nav-inside li a:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .inside-title{
    color: $white !important;
    &:after{
      background: $white !important;
    }
  }
}

.nav>li.inside-title{
  display: none;
}

.light-menu aside.sidebar li.active ul li a:hover {
    background: rgba(0, 0, 0, 0.2);
}


.metismenu li:first-child:hover {
    background: none;
}

.metismenu li:not(".profile-sidebar-container"):hover, .metismenu li>a:focus, .metismenu li>a:hover {
    background: rgba(0, 0, 0, 0.1) !important;
}

.metismenu .active:hover {
    background: none;
}


.boxed-layout.fixed-sidebar{
  .container-fluid{
    margin-left: auto !important;
    padding-left: 280px;
    max-width: 1400px;
  }
}

@media(min-width: 1200px){
  body.icon-menu.horizontal-menu aside.sidebar{
    width: 100%;
    height: 85px;
    .nav > li{
      display: inline-block;
      &:before{
        width: 100%;
        height: 3px;
        z-index: 999;
      }
      .nav.nav-inside .inside-title{
        display: none;
      }
      .nav.nav-inside li{
        display: block;
      }
//      > a{
//        height: 85px;
//      }
    }
  }

  body.icon-menu.horizontal-menu .nav.nav-inside{
    top: 83px;
    left: auto;
    background: none;
    min-height: 301px;
    li{
      background: #121D2D;
    }
  }
  
  body.icon-menu.horizontal-menu .nav.nav-inside .nav-inside{
    background: #121d2d;
    margin-left: 280px;
  }
  
  body.icon-menu.horizontal-menu .nav.nav-inside .menu-child:hover a{
    z-index: 99;
  }


  body.icon-menu.horizontal-menu .profile-sidebar-container{
    display: none !important;
  }

  body.icon-menu.horizontal-menu .container-fluid {
    margin-left: 0;
    margin-top: 85px;
  }
  body.fixed-all.icon-menu.horizontal-menu .container-fluid {
    margin-left: 0;
    margin-top: 155px;
  }
  
  body.icon-menu.horizontal-menu .side-panel {
    top: 155px;
  }
  
  body.fixed-all.icon-menu.horizontal-menu .side-panel {
    top: 155px !important;
  }
}

body.fixed-sidebar.icon-menu nav.navbar .navbar-header, body.fixed-sidebar-example.icon-menu nav.navbar .navbar-header{
  @media(max-width: 767px){
    width: 70px;
  }
}





.side-panel{
  background: $white;
  width: 280px;
  position: absolute;
  right: 0;
  height: calc(100% - 70px);
  z-index: 11;
  @include shadow(2);
  opacity: 0;
  transform: translateX(280px);
  transition: transform .3s, opacity .2s;
  &.open{
    transform: translateX(0);
    opacity: 1;
  }
  .nav-tabs.nav-justified>li {
    display: table-cell !important;
    width: 1%;
  }
  .nav-tabs a{
    border-radius: 0 !important;
    border: 0 !important;
    font-size: 22px;
  }
  table td{
    padding: 5px 15px !important;
  }
  
  .timeline {
    padding-left: 0;
    list-style: none;
    position: relative;
    margin: 0;
  }
  .timeline li:last-child .timeline-body {
    border-bottom: 0 !important;
  }
  .timeline:before {
    content: '';
    position: absolute;
    top: 10px;
    bottom: 0;
    width: 2px;
    background: #f1f1f1;
    left: 6%;
    margin-left: 2px;
  }
  .timeline > li {
    position: relative;
  }
  .timeline > li .timeline-header {
    padding: 0;
    color: #bdbdbd;
  }
  .timeline > li .timeline-header span.date {
    font-weight: lighter;
    font-size: 11px;
  }
  .timeline > li .timeline-header span.author {
    font-size: 13px;
    display: block;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }
  .timeline > li .timeline-header + p {
    padding-top: 10px;
  }
  .timeline > li .timeline-icon {
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 31px;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    color: #9e9e9e;
    background: #f1f1f1;
    border-radius: 50%;
    text-align: center;
    left: 6%;
    top: 0;
    margin: 0 0 0 -12px;
    padding: 0;
  }
  .timeline > li .timeline-icon i {
    line-height: inherit;
    font-size: 16px;
    color: $white;
  }
  .timeline > li .timeline-body {
    margin: 0 0 16px 20%;
    padding: 0 0 8px;
    position: relative;
    border-width: 0;
    border-style: solid;
    background-color: #fff;
  }
  .timeline > li .timeline-body .timeline-content ul {
    margin: 0;
  }
  .timeline > li .timeline-body .timeline-content ul li {
    padding-left: 0;
    padding-right: 8px;
  }
  .timeline > li .timeline-body .timeline-content p {
    margin-bottom: 0;
  }
  .timeline > li .timeline-body .timeline-content p + p {
    margin-top: 10px;
  }
  .timeline > li .timeline-body img {
    margin: 0;
  }
  .timeline > li .timeline-footer {
    padding-top: 20px;
    color: #fff;
  }
  .side-contacts .media-body > span {
    font-weight: 500;
  }
  .side-contacts a:hover {
    text-decoration: none;
  }
  .side-contacts .contact-status {
    display: block;
    font-size: 11px;
    text-transform: uppercase;
  }
  .side-contacts img{
    max-width: 40px;
  }
  .side-title{
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding: 10px 15px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 500;
  }
}

.current-block:before{
  content: "";
  width: 3px;
  height: 100%;
  position: absolute;
  background: #FFC107;
  z-index: 2;
  opacity: 1;
}

.current-block>a{
  color: $white !important;
  font-weight: bold !important;
}

.current-page {
  background: rgba(255,255,255,0.2) !important;
  color: $white !important;
}


body.small-content .container-fluid{
  margin-right: 280px;
}